<script>
import Layout from "@/views/layouts/main";
import PageHeader from "@/components/page-header";
import CollaboratorShow from "./show/show.vue";
import CollaboratorForm from "./forms/collaborator-form.vue";
import CustomTable from "@/components/tables/custom-table.vue";
import AddButton from "@/components/buttons/add-button.vue";
import Status from "@/helpers/general/status";
import SelectField from '@/components/forms/select-field.vue';
import SelectAutocompleteField from "@/components/forms/select-autocomplete-field.vue";
import DateRangeField from '@/components/forms/date-range-field.vue';

/**
 * Collaborators Component
 */
export default {
  components: {
    Layout,
    PageHeader,
    CollaboratorShow,
    CustomTable,
    CollaboratorForm,
    AddButton,
    SelectField,
    SelectAutocompleteField,
    DateRangeField
  },
  data() {
    return {
      showModalId:'collaborator-show-modal',
      formModalId:'collaborator-form-modal',
      pageHeader:{
        title: "Colaboradores",
        breadcrumbs: [
          {
            text: "Colaboradores",
            href: "/collaborators",
            active: true
          }
        ],
      },
      collaborator_status: Status.getList('collaborator_status'),
      table:{
        url:'/collaborators',
        params: {
          'with_trashed': 1,
        },
        fields:[
          { key: "id", label: "Cod", sortable: true },
          { key: "created_at", label: "Fecha de creación", sortable: true },
          { key: "business_name", label: "Nombre", sortable: true },
          { key: "email", label: "Email", sortable: false },
          { key: "phone", label: "Teléfono", sortable: false },
          { key: "status", label: "Estado", sortable: false },
        ],
        formatted_fields: [
            'created_at',
            'status',
        ]
      },
      filters: {
          business_name: null,
          contact_person: null,
          contract_number: null,
          identification: null,
          email: null,
          phone: null,
          address:null,
          postcode:null,
          status: [],
          province_id: null,
          region_id: null,
          population_id: null,
          establishment_id: null,
          created_at_range: {
              startDate: null,
              endDate: null
          },
      },
      formSelectedItemId:null,   
      showSelectedItemId:null,
      clone:false
    };
  },
  methods: {
    showViewModal(item) {
      this.showSelectedItemId = item?.id || null;
      this.$bvModal.show(this.showModalId);
    },
    showFormModal(item) {
      this.formSelectedItemId = item?.id || null;
      this.$bvModal.show(this.formModalId);
    },
    formatter(field, item) {
        switch (field) {
            case 'status':
                return Status.getStatus({ itemStatus: item?.status, type: 'collaborator_status', html: true });
            default:
                return '';
        }
    },
    onSuccess(){
      this.$refs.collaboratorTable.refresh();
    }
  }
};
</script>

<template>
  <Layout>
    <PageHeader :title="pageHeader?.title" :items="pageHeader?.breadcrumbs" />
    <div class="row">
      <div class="col-lg-12">
        <div class="card">
          <div class="card-body">
            <CollaboratorShow :modal-id="showModalId" :id="showSelectedItemId"></CollaboratorShow>
            <CollaboratorForm :modal-id="formModalId" :id="formSelectedItemId" @onSuccess="onSuccess"></CollaboratorForm>
            <CustomTable
              :url="table.url"
              :fields="table.fields"
              :formatter="formatter"
              :formatted-fields="table.formatted_fields"
              :params="table.params"
              :filters2="filters"
              ref="collaboratorTable"
              :show-view="true"
              :show-edit="true"
              :showSoftDelete="false"
              @show="showViewModal"
              @edit="showFormModal"
              :show-modal-id="showModalId"
            >
              <template v-slot:header-actions>
                <AddButton @click="showFormModal"></AddButton>
              </template>
              <template v-slot:filters="{ filters }">
                  <div class="row" v-if="!filters?.card_application_custom_filter_id">
                      <div class="form-group col-12 col-lg-3 col-sm-6">
                          <label for="business_name">Razón social</label>
                          <input id="business_name" v-model="filters.business_name" type="text" class="form-control"
                              placeholder="Filtrar por razón social" />
                      </div>
                      <div class="form-group col-12 col-lg-3 col-sm-6">
                          <label for="contact_person">Persona de contacto</label>
                          <input id="contact_person" v-model="filters.contact_person" type="text" class="form-control"
                              placeholder="Filtrar por persona de contacto" />
                      </div>
                      <div class="form-group col-12 col-lg-3 col-sm-6">
                          <label for="identification">Identificación</label>
                          <input id="identification" v-model="filters.identification" type="text"
                              class="form-control" placeholder="Filtrar por identificación" />
                      </div>
                      <div class="form-group col-12 col-lg-3 col-sm-6">
                          <label>Establecimiento</label>
                          <SelectAutocompleteField
                              :url="`/establishments`"
                              :only-ids="true"
                              :multiple="false"
                              :placeholder="'Busca un establecimiento'"
                              :label="'trade_name'"
                              v-model="filters.establishment_id"
                          ></SelectAutocompleteField>
                      </div>
                      <div class="form-group col-12 col-lg-3 col-sm-6">
                          <label for="contract_number">Número de contrato</label>
                          <input id="contract_number" v-model="filters.contract_number" type="text"
                              class="form-control" placeholder="Filtrar por contrato" />
                      </div>
                      <div class="form-group col-12 col-lg-3 col-sm-6">
                          <label for="phone">Fecha de creación</label>
                          <DateRangeField v-model="filters.created_at_range" />
                      </div>                      
                      <div class="form-group col-12 col-lg-3 col-sm-6">
                          <label for="status">Estado</label>
                          <SelectField label="label" placeholder="Todos" :multiple="true"
                              :options="collaborator_status" v-model="filters.status"></SelectField>
                      </div>                      
                      <div class="form-group col-12 col-lg-3 col-sm-6">
                          <label for="phone">Teléfono</label>
                          <input id="phone" v-model="filters.phone" type="text" class="form-control"
                              placeholder="Filtrar por teléfono" />
                      </div>
                      <div class="form-group col-12 col-lg-3 col-sm-6">
                          <label for="email">Email</label>
                          <input id="email" v-model="filters.email" type="text" class="form-control"
                              placeholder="Filtrar por email" />
                      </div>
                      <div class="form-group col-12 col-lg-3 col-sm-6">
                          <label for="address">Dirección</label>
                          <input id="address" v-model="filters.address" type="text" class="form-control"
                              placeholder="Filtrar por dirección" />
                      </div>
                      <div class="form-group col-12 col-lg-3 col-sm-6">
                          <label for="postcode">Código postal</label>
                          <input id="postcode" v-model="filters.postcode" type="text" class="form-control"
                              placeholder="Filtrar por CP" />
                      </div>
                      <div class="form-group col-12 col-lg-3 col-sm-6">
                          <label>Provincia</label>
                          <SelectAutocompleteField
                              :url="`/provinces`"
                              :only-ids="true"
                              :multiple="false"
                              :placeholder="'Busca una provincia'"
                              :label="'name'"
                              v-model="filters.province_id"
                          ></SelectAutocompleteField>
                      </div>
                      <div class="form-group col-12 col-lg-3 col-sm-6">
                          <label>Comarca</label>
                          <SelectAutocompleteField
                              :url="`/regions`"
                              :only-ids="true"
                              :multiple="false"
                              :placeholder="'Busca una comarca'"
                              :label="'fullname'"
                              v-model="filters.region_id"
                              :params="{province_id: filters.province_id}"
                          ></SelectAutocompleteField>
                      </div>
                      <div class="form-group col-12 col-lg-3 col-sm-6">
                          <label>Población</label>
                          <SelectAutocompleteField
                              :url="`/populations`"
                              :only-ids="true"
                              :multiple="false"
                              :placeholder="'Busca una población'"
                              :label="'fullname'"
                              v-model="filters.population_id"
                              :params="{region_id: filters.region_id}"
                          ></SelectAutocompleteField>
                      </div>
                  </div>
              </template>
              <template #cell-created_at="{ item }">
                  <span v-format-date="item?.created_at"></span>
              </template>
            </CustomTable>
          </div>
        </div>
      </div>
    </div>
  </Layout>
</template>